import axios from "axios"

const BaseApiUrl = process.env.GATSBY_API_URL

const guardianCounselApi = axios.create({
  baseURL: BaseApiUrl,
})

guardianCounselApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem("auth.jwt")

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

export default guardianCounselApi
