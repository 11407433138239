// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-classroom-index-js": () => import("./../src/pages/classroom/index.js" /* webpackChunkName: "component---src-pages-classroom-index-js" */),
  "component---src-pages-classroom-manage-js": () => import("./../src/pages/classroom/manage.js" /* webpackChunkName: "component---src-pages-classroom-manage-js" */),
  "component---src-pages-dashboard-js": () => import("./../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitations-create-js": () => import("./../src/pages/invitations/create.js" /* webpackChunkName: "component---src-pages-invitations-create-js" */)
}

